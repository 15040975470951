import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { Button, Select, TextInput, Checkbox, Text, Box, Stack, Group, Anchor } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import backendConfig from 'config/backend.config';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectShouldConfirm, setShouldConfirm } from 'pages/settings/SettingsSlice';
import { toast } from 'react-toastify';
import useGetCountries from 'hooks/useGetCountries';
import { fetchData } from 'utils/apiUtils';

type FormInput = {
  first_name?: string;
  last_name?: string;
  email?: string;
  middle_names?: string;
  country_of_residence?: string;
  accredited_investor?: any;
};

interface SettingsProps {
  onSubmitSuccess?: () => void;
}

const BasicSettingsPage: React.FC<SettingsProps> = ({ onSubmitSuccess }) => {
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently, user } = useAuth0();
  const [formInput, setFormInput] = useState<FormInput>({});
  const shouldConfirm = useAppSelector(selectShouldConfirm);
  const [searchValue, onSearchChange] = useState('');
  const [selectedCountry, setSelectedCountry] = useState<string | null>('');
  const { data: countries } = useGetCountries();

  useEffect(() => {
    setFormInput((val) => ({
      ...val,
      email: user?.email,
      country_of_residence: selectedCountry === null ? '' : selectedCountry,
    }));
  }, [selectedCountry, user]);

  const handleFormInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { name, value, checked } = e.currentTarget;
      if (name === 'accredited_investor') {
        setFormInput({ ...formInput, [name]: checked });
      } else {
        setFormInput({ ...formInput, [name]: value });
      }
    },
    [formInput]
  );

  const getUserDetails = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetchData({
        url: `${backendConfig.baseUrl}v1/users/${user?.sub}`,
        method: 'GET',
        token,
      });
      setSelectedCountry(response?.country_of_residence || '');
      setFormInput((val) => ({ ...formInput, ...val, ...response }));
    } catch (err) {
      console.error(err);
    }
  }, [formInput, getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    getUserDetails();
  }, []);

  const postSubmit = (response: any) => {
    const isAccredited = response.accredited_investor === true || false;
    dispatch(setShouldConfirm(!isAccredited));
    onSubmitSuccess?.();
    window.location.reload();
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const token = await getAccessTokenSilently();
    try {
      const response = await fetchData({
        url: `${backendConfig.baseUrl}v1/users/${user?.sub}`,
        method: 'POST',
        data: JSON.stringify(formInput),
        token,
      });
      toast.success('Information saved successfully.');
      postSubmit(response);
    } catch (err) {
      toast.error('Failed to save information! Please try again.');
    }
  };

  const checkIfValuesProvided = () =>
    Boolean(
      formInput?.country_of_residence &&
        formInput?.first_name &&
        formInput?.last_name &&
        formInput?.email &&
        formInput?.accredited_investor
    );

  return (
    <Box mx="auto" maw={400}>
      <form onSubmit={onSubmit}>
        <Stack>
          <TextInput
            label="First name"
            placeholder="First name"
            name="first_name"
            defaultValue={formInput?.first_name ?? ''}
            onChange={handleFormInputChange}
          />
          <TextInput
            label="Middle name"
            placeholder="Middle name(s)"
            name="middle_names"
            defaultValue={formInput?.middle_names ?? ''}
            onChange={handleFormInputChange}
          />
          <TextInput
            label="Last name"
            placeholder="Last name"
            name="last_name"
            defaultValue={formInput?.last_name ?? ''}
            onChange={handleFormInputChange}
          />
          <TextInput
            label="Email address"
            placeholder="Email address"
            name="email"
            disabled
            value={user?.email ?? ''}
          />

          <Select
            label="Country of residence"
            placeholder="Select Country"
            searchable
            onSearchChange={onSearchChange}
            searchValue={searchValue}
            onChange={setSelectedCountry}
            value={selectedCountry}
            data={countries}
          />

          <Checkbox
            radius="xs"
            checked={formInput?.accredited_investor}
            name="accredited_investor"
            defaultValue={formInput?.accredited_investor}
            onChange={handleFormInputChange}
            label={
              <Text fz="md">
                I verify that I am an{' '}
                <Anchor
                  className="highlighted-anchor"
                  href="https://help.angellist.com/hc/en-us/articles/360048803251-Accredited-Investors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  accredited
                </Anchor>{' '}
                investor
              </Text>
            }
          />
          <Group position="center">
            <Button disabled={!checkIfValuesProvided()} type="submit">
              {shouldConfirm ? 'Confirm' : 'Update'}
            </Button>
          </Group>
        </Stack>
      </form>
    </Box>
  );
};

BasicSettingsPage.defaultProps = {
  onSubmitSuccess: () => {},
};

export default BasicSettingsPage;
