import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import backendConfig from 'config/backend.config';
import { fetchData } from 'utils/apiUtils';

export default function useGetCountries() {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState<{ label: string; value: string }[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchCountries = async () => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await fetchData({
          url: `${backendConfig.baseUrl}v1/countries`,
          method: 'GET',
          token,
        });
        const transformedData = Object.keys(response).map((code) => ({
          label: response[code],
          value: code,
        }));
        setData(transformedData);
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCountries();
  }, [getAccessTokenSilently]);

  return { data, isLoading, error };
}
