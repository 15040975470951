import { dealsApi } from 'services/deals/deals.service';
import { combineReducers } from '@reduxjs/toolkit';
import { portfolioApi } from 'services/invest/portfolio.service';

import SettingsSlice from 'pages/settings/SettingsSlice';
import { investApi } from 'services/invest/invest.service';
import { entitiesApi } from 'services/invest/entities.service';
import { referralApi } from 'services/referral/referral.service';
import { BankingDetailsApi } from 'services/banking/banking.service';
import { auth0Reducer } from 'components/Auth0/slices/auth0Slice';

export default combineReducers({
  [investApi.reducerPath]: investApi.reducer,
  [dealsApi.reducerPath]: dealsApi.reducer,
  [portfolioApi.reducerPath]: portfolioApi.reducer,
  [entitiesApi.reducerPath]: entitiesApi.reducer,
  [referralApi.reducerPath]: referralApi.reducer,
  [BankingDetailsApi.reducerPath]: BankingDetailsApi.reducer,
  settings: SettingsSlice,
  auth0: auth0Reducer,
});
