import React, { useEffect, useState } from 'react';
import backendConfig from 'config/backend.config';
import { IDeals } from 'features/Deal';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData } from 'utils/apiUtils';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { Box, Button, Text, Stack, Flex } from '@mantine/core';
import { useRequestSignUrlMutation } from 'services/invest/invest.service';
import { InvestApi } from 'models/invest/invest.api.model';
import NotFound from 'pages/error/404/components/NotFound';
import { PATH_LOGIN, PATH_DASHBOARD, PATH_ROOT } from 'shared/constants';
import { getCurrencySymbol, formatNumber } from 'utils/currencySymbols';
import HelloDoc from 'components/HelloDocSign/HelloDoc';
import InvestmentInfo from 'components/Common/InvestmentInfo';
import './InvestmentPage.scss';
import FirstTimeInvestor from 'components/Common/FirstTimeInvestor';
import WiringComponent from 'components/Common/Wired/WiringComponent';
import { InvestmentStatus } from 'shared/enums';
import { Entity } from 'models/invest/entity.api.model';
import { getLocalStorage } from 'utils/browserUtil';
import { PaEvents, ProductAnalytics } from 'analytics';
import InvestmentPageSkeleton from './InvestmentPageSkeleton';
import InvestmentForm from './InvestmentForm';
import TermsAndPayment from './TermsAndPayment';
import RadioCardGroup from './RadioCardGroup';

const InvestmentComponent: React.FC = () => {
  const { user: authenticatedUser, isLoading: isAuthLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { dealId } = useParams<{ dealId: string }>();
  const navigate = useNavigate();
  const [data, setData] = useState<IDeals | null>();
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const getDealById = async (id: string) => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    try {
      const responseData = await fetchData({
        url: `${backendConfig.baseUrl}v1/deals/${id}`,
        method: 'GET',
        token,
      });
      setData(responseData);
    } catch (e) {
      console.error(e);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (dealId) {
      getDealById(dealId);
    }
  }, []);

  const investments = useSelector((state: any) => state.auth0?.investments);
  const [investmentValue, setInvestmentValue] = useState<number | undefined>(
    investments?.[dealId || ''] || getLocalStorage(`${dealId}-investment`)
  );
  const [checkboxValue, setCheckboxValue] = useState<string[]>([]);
  const [signUrl, setSignUrl] = useState<string | null>(null);
  const [signatureId, setSignatureId] = useState<string | null>(null);
  const [requestSignUrl, { isLoading: isRequesting }] = useRequestSignUrlMutation();
  const [openWiringModel, setWiringModel] = useState<boolean>(false);
  const [entity, setEntity] = React.useState<Entity | undefined>(undefined);

  const domainUrl = process.env.REACT_APP_ROOT_DOMAIN || '';
  const isUserVerified = authenticatedUser?.[`${domainUrl}/roles`]?.includes('verified');
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthLoading) return;
    if (!isAuthenticated) {
      navigate(PATH_LOGIN);
    } else if (isAuthenticated && authenticatedUser && !isUserVerified) {
      navigate(PATH_ROOT);
    }
    ProductAnalytics.trackPageView('invest-page', {
      deal_id: dealId
    });
  }, [authenticatedUser, isAuthLoading, isAuthenticated, isUserVerified, navigate]);

  const handleSuccess = () => {
    setWiringModel(true);
  };

  const handleCancel = async () => {
    alert('Document signing cancelled');
  };

  const handleInvestmentCreation = async () => {
    if (
      checkboxValue.length !== 3 ||
      !investmentValue ||
      !entity ||
      (investmentValue && investmentValue < Number(data?.min_validation))
    ) {
      setShowError(true);
    } else {
      setShowError(false);
      const accessToken = await getAccessTokenSilently();
      const investmentDetails: InvestApi = {
        investmentPayload: {
          risk_factors: checkboxValue.includes('risk_factors'),
          subscription_agreement: checkboxValue.includes('subscription_agreement'),
          privacy_policy_consent: checkboxValue.includes('privacy_policy_consent'),
          tos_consent: checkboxValue.includes('privacy_policy_consent'),
          amount: investmentValue || 0,
          currency: 'USD',
        },
        companyName: data?.company_name || '',
        companyId: dealId || '',
        dealId: dealId || '',
        accessToken,
        userId: authenticatedUser?.sub ?? '',
        entity_id: entity?.id,
      };
      try {
        ProductAnalytics.trackEvent(PaEvents.COMMIT_DEAL, {
          deal_id: dealId,
          investment_amount: investmentValue,
        });
        const response = await requestSignUrl(investmentDetails).unwrap();
        setSignUrl(response.sign_url);
        setSignatureId(response.signature_id);
      } catch (err) {
        toast.error('Something, went wrong please contact our support team');
        console.error('Error requesting sign URL', err);
      }
    }
  };

  const handleError = (err: any) => {
    console.error(err);
  };

  const handleWiredClose = () => {
    setWiringModel(false);
    toast.info('Thanks for your commitment. Please wire the funds to complete the investment');
    navigate(PATH_DASHBOARD);
  };

  if (!error && (isLoading || isAuthLoading || !data)) {
    return <InvestmentPageSkeleton />;
  }

  if (!isLoading && !isAuthLoading && error) {
    return <NotFound />;
  }

  const currency = getCurrencySymbol(data?.currency || 'USD');
  const investMent = formatNumber(investmentValue || 0);

  return (
    <Flex direction={{ base: 'column-reverse', md: 'row' }} gap="lg">
      <Box sx={{ flex: 2 }}>
        <InvestmentForm
          investmentValue={investmentValue}
          setInvestmentValue={setInvestmentValue}
          currency={currency}
          min_validation={Number(data?.min_validation) || 0}
        />

        <div
          className={`${
            showError && !entity ? 'error-box' : 'normal-box'
          }`}
        >
          <RadioCardGroup value={entity?.id} setValue={setEntity} />
        </div>
        <FirstTimeInvestor />
        <TermsAndPayment
          dealId={dealId || ''}
          showError={showError}
          checkboxValue={checkboxValue}
          setCheckboxValue={setCheckboxValue}
          termSheet={data?.subscription_agreement_document_url || ''}
          signatureSheet={data?.signature_document_page_url || ''}
        />
        <Stack mt="lg">
          <Button onClick={handleInvestmentCreation} loading={isRequesting}>
            Commit & E-Sign
          </Button>
          {showError && <Text color="red"> Please fill out all required fields.</Text>}
          <Text color="dimmed" className="wired-commitment-message" size="sm">
            Wire transfer of ${investMent} will be required upon commitment. Once e-signed your commitment amount cannot
            be cancelled or reduced.
          </Text>
        </Stack>

        <Text color="dimmed" size="sm" my={50}>
          Tokenfolio and its affiliates do not provide investment advice to investors and the information on this page
          and the preceding deal page should not be relied upon as research, investment advice or a recommendation of
          any kind.
          <br />
          <br />
          Investment opportunities posted on this website are `private placement` of securities that are not publicly
          traded, and liquidity cannot be guaranteed. Investing in private companies may be considered highly
          speculative and involves a high degree of risk, including the risk of substantial loss of investment.
          Investors must be able to afford the loss of their entire investment.
        </Text>
      </Box>
      <Box sx={{ flex: 1 }}>
        <InvestmentInfo 
          data={{
            companyName: data?.company_name,
            currency: data?.currency,
            offeringValuation: data?.offering_valuation,
            logoUrl: data?.logo_url,
            managementFees: data?.management_fees,
            carriedInterest: data?.carried_interest,
            setupFees: data?.setup_fees
          }}
          committedAmount={investmentValue || 0}
          showWalletBalance={false}
        />
      </Box>
      {signUrl && signatureId && (
        <HelloDoc
          signUrl={signUrl}
          dealId={dealId || ''}
          totalAmount={investmentValue || 0}
          onSuccess={handleSuccess}
          onError={handleError}
          onCancel={handleCancel}
        />
      )}
      <WiringComponent
        isOpen={openWiringModel}
        handleClose={handleWiredClose}
        activeStage={InvestmentStatus.COMMITTED.rank}
        amount={investmentValue}
        currency={currency}
        data={{
          dealId: data?.id || '',
          companyName: data?.company_name || '',
          totalInvestment: investmentValue || 0,
          logoUrl: data?.logo_url || '',
          managementFees: data?.management_fees || 0,
          carriedInterest: data?.carried_interest || 0,
          setupFees: data?.setup_fees || 0,
          offeringValuation: data?.offering_valuation || 0,
        }}
      />
    </Flex>
  );
};

export default InvestmentComponent;
