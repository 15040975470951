export const TRANSLATE_STRING = {
  COMMON_SHARES: 'Common shares',
  PREFERRED_SHARES: 'Preferred shares',
  SERIES_A_PREFERRED_SHARES: 'Series A Preferred',
  SERIES_B_PREFERRED_SHARES: 'Series B Preferred',
  SERIES_C_PREFERRED_SHARES: 'Series C Preferred',
  SERIES_D_PREFERRED_SHARES: 'Series D Preferred',
  CONVERTIBLE_NOTE: 'Convertible note',
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  DIRECT: 'Direct',
  INDIRECT: 'Indirect',
  NOT_VERIFIED: 'Not verified',
  VERIFIED: 'Verified',
  UNDER_REVIEW: 'Under review',
  DENIED: 'Denied',
};
