import React from 'react';
import { Card, Text, Group, Divider, Checkbox, HoverCard } from '@mantine/core';
import { Link } from 'react-router-dom';
import { IconInfoCircle } from '@tabler/icons';
import { PATH_INVITE_FRIENDS } from 'shared/constants';
import CompanyHeader from 'components/Common/CompanyHeader';

import { getCurrencySymbol, formatNumberWithCommas, formatNumber } from 'utils/currencySymbols';

const InvestmentInfo: React.FC<{ data?: any; committedAmount: number; showWalletBalance: boolean }> = ({
  data,
  committedAmount,
  showWalletBalance,
}) => {
  const currency = getCurrencySymbol(data?.currency || 'USD');
  const valuation = `${currency} ${formatNumberWithCommas(data?.offeringValuation)} valuation`;

  const walletBalance = 1000;
  const walletBalanceInStr = `${currency}${formatNumber(String(walletBalance))}`;

  const tooltipMessage =
    walletBalance <= 0 ? (
      <span>
        Get $1000 by referring a friend.{' '}
        <Link to={PATH_INVITE_FRIENDS} style={{ textDecoration: 'underline', color: '#007BFF' }}>
          Click here to Refer
        </Link>
        .
      </span>
    ) : (
      'First-time investors: Credits will be used for investments over $10,000 and against management fees.'
    );

  return (
    <Card shadow="md" p="lg" radius="md">
      <CompanyHeader logoUrl={data?.logoUrl} name={data?.companyName} summary={valuation} size="sm" />
      <Group mt="lg" position="apart">
        <Text size="sm"> {data?.managementFees}% Mgmt fees</Text>
        <Divider orientation="vertical" />
        <Text size="sm"> {data?.carriedInterest}% Carry</Text>
        <Divider orientation="vertical" />
        <Text size="sm"> {data?.setupFees || 0.5}% Setup fees</Text>
      </Group>
      <Group mt="sm" align="space-between" className="details-spacing-contatiner">
        <Divider />
        <Group position="apart">
          <Text fw={550} size="sm">
            Committed amount
          </Text>
          <Text color="dimmed" size="sm" fw={550}>
            {currency}
            {formatNumber(String(committedAmount))}
          </Text>
        </Group>
        {showWalletBalance && (
          <>
            <Divider />
            <Group position="apart">
              <Checkbox
                label={
                  <Group spacing="sm">
                    <Text>Use TF wallet credits</Text>
                    <Text weight="bold" color="teal">
                      {walletBalanceInStr}
                    </Text>
                  </Group>
                }
                checked
              />
              <HoverCard shadow="md">
                <HoverCard.Target>
                  <IconInfoCircle size={18} style={{ cursor: 'pointer' }} color="var(--mantine-color-primary-6)" />
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Text size="sm">{tooltipMessage}</Text>
                </HoverCard.Dropdown>
              </HoverCard>
            </Group>
          </>
        )}
      </Group>
    </Card>
  );
};

InvestmentInfo.defaultProps = {
  data: null,
};

export default InvestmentInfo;
