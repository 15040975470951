/* eslint-disable react/require-default-props */
import React, { useCallback, useEffect, useState } from 'react';
import backendConfig from 'config/backend.config';
import { fetchData } from 'utils/apiUtils';
import { IDeals } from 'features/Deal';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Text, Group, Badge, Box, Title, Button, Flex } from '@mantine/core';
import { getCurrencySymbol } from 'utils/currencySymbols';
import NotFound from 'pages/error/404/components/NotFound';
import CompanyHeader from 'components/Common/CompanyHeader';
import { useSelector, useDispatch } from 'react-redux';
import { updateInvestment } from 'components/Auth0/slices/auth0Slice';
import { PATH_LOGIN, PATH_SETTINGS_BASIC } from 'shared/constants';
import { setLocalStorage } from 'utils/browserUtil';
import { useAppSelector } from 'store/hooks';
import { selectShouldConfirm } from 'pages/settings/SettingsSlice';
import { PaEvents, ProductAnalytics, trackDocumentView } from 'analytics';
import { useRegisterInterest } from 'hooks/useRegisterInterest';
import InvestmentForm from './InvestmentForm';
import DealsPageSkeleton from './DealPageSkeleton';

const CompanyInfo: React.FC<{ data?: any }> = ({ data }) => (
  <Box className="company-info">
    <CompanyHeader logoUrl={data?.logo_url} name={data?.company_name} summary={data?.company_summary} size="lg" />
    <Group spacing={6} my="lg" className="company-profile">
      {data?.tags.map((tag: any) => (
        <Badge key={tag} radius="sm">
          {tag}
        </Badge>
      ))}
    </Group>
  </Box>
);

const CompanyDetails: React.FC<{ data?: any }> = ({ data }) => (
  <Box className="company-details">
    <Title order={3} weight={500}>
      Company
    </Title>
    <Text fz="md" mt={20} color="var(--mantine-color-dark-7)">
      {data?.company_info}
    </Text>
  </Box>
);

const InvestMentMemo: React.FC<{ link: string, dealId: string }> = ({ link, dealId }) => (
  <Box className="company-investment-memo">
    <Button
      data-event="investment_memo"
      component="a"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      variant="filled"
      fz="md"
      mt={20}
      onClick={(e) => {
        trackDocumentView(e, {deal_id: dealId, document: 'investment-memo'});
      }}
    >
      View investment Memo
    </Button>
  </Box>
);

const DealsComponent: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dealId = useParams<{ dealId: string }>().dealId ?? '';
  const [data, setData] = useState<IDeals | null>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const getDealById = async (id: string) => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    try {
      const responseData = await fetchData({
        url: `${backendConfig.baseUrl}v1/deals/${id}`,
        method: 'GET',
        token,
      });
      setData(responseData);
    } catch (e) {
      console.error(e);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const user = useSelector((state: any) => state.auth0.user);

  useEffect(() => {
    if (dealId) {
      getDealById(dealId);
      ProductAnalytics.trackPageView('deal-page', {
        deal_id: dealId
      });
    }
  }, []);
  const { registerInterest } = useRegisterInterest(dealId);
  const shouldConfirm = useAppSelector(selectShouldConfirm);

  const handleInvestClick = useCallback(
    (investmentValue: number) => {
      if (!user) {
        navigate(PATH_LOGIN); // Redirect to login
      } else if (shouldConfirm) {
        // Show verification prompt
        navigate(PATH_SETTINGS_BASIC);
      } else {
        // Proceed with investment
        setLocalStorage(`${dealId}-investment`, investmentValue);
        dispatch(updateInvestment({ dealId: dealId || '', investmentValue }));
        navigate(`/${dealId}/invest`);
      }
    },
    [dealId, dispatch, navigate, shouldConfirm, user]
  );

  const currency = getCurrencySymbol(data?.currency || 'USD');

  const investHandler = useCallback(
    (investmentValue: number) => {
      if (data?.can_invest) {
        handleInvestClick(investmentValue);
        ProductAnalytics.trackEvent(PaEvents.INVEST_CLICK, {
          amount: investmentValue,
          can_invest: true,
          deal_id: dealId,
          is_verified: true,
          low_value: false,
        });
      } else {
        registerInterest(investmentValue);
        ProductAnalytics.trackEvent(PaEvents.INVEST_CLICK, {
          amount: investmentValue,
          can_invest: false,
          deal_id: dealId,
          is_verified: true,
          low_value: false,
        });
      }
    },
    [data?.can_invest, dealId, handleInvestClick, registerInterest]
  );

  if (!error && (isLoading || !data)) {
    return <DealsPageSkeleton />;
  }

  if (!isLoading && error) {
    return <NotFound />;
  }

  return (
    <Flex direction={{ base: 'column', md: 'row' }} gap="lg">
      <Box style={{ flex: 2 }}>
        <CompanyInfo data={data} />
        <CompanyDetails data={data} />
        <InvestMentMemo link={data?.investment_memo_document_url || ''} dealId={data?.id || ''} />
      </Box>
      <Box style={{ flex: 1 }}>
        <InvestmentForm
          data={data}
          currency={currency}
          isUserVerified={!shouldConfirm}
          user={user}
          onInvestClick={investHandler}
        />
      </Box>
    </Flex>
  );
};

export default DealsComponent;
