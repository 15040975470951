import { FieldTypesEnum } from 'utils/datetime';

export const dealsFields = [
  { key: 'share_price', label: 'Share price', type: FieldTypesEnum.Currency },
  { key: 'offering_valuation', label: 'Offering valuation', type: FieldTypesEnum.Currency },
  { key: 'instrument', label: 'Share type', type: FieldTypesEnum.Translate },
  { key: 'deal_type', label: 'Deal type', type: FieldTypesEnum.Translate },
  { key: 'deal_structure', label: 'Deal structure', type: FieldTypesEnum.Link },
  { key: 'min_investment', label: 'Preferred min. investment', type: FieldTypesEnum.Currency },
  { key: 'management_fees', label: 'Management fees (Total)', type: FieldTypesEnum.Percentage },
  { key: 'carried_interest', label: 'Carried interest', type: FieldTypesEnum.Percentage },
];

export function formatString(input: string): string {
  return input
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());
}
