import React from 'react';

import { SimpleGrid, Skeleton } from '@mantine/core';

const DealsSkeleton: React.FC = () => (
  <SimpleGrid
    cols={6}
    spacing="xl"
    my="lg"
    breakpoints={[
      { maxWidth: 1920, cols: 5, spacing: 'xl' },
      { maxWidth: 1600, cols: 4, spacing: 'xl' },
      { maxWidth: 1200, cols: 3, spacing: 'lg' },
      { maxWidth: 992, cols: 2, spacing: 'md' },
      { maxWidth: 768, cols: 1, spacing: 'sm' },
    ]}
  >
    <Skeleton height={435} animate />
    <Skeleton height={435} animate />
    <Skeleton height={435} animate />
    <Skeleton height={435} animate />
  </SimpleGrid>
);

export default DealsSkeleton;
