import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from 'utils/apiUtils';
import { RootState } from 'store/store';
import { IBankingDetails } from '../../features/BankingDetails/models/banking.model';

export const BankingDetailsApi = createApi({
  reducerPath: 'bankingDetailsApi',
  baseQuery: async (args, api, extraOptions) => {
    const { token } = (api.getState() as RootState).auth0;
    const baseQuery = createBaseQuery(token);
    return baseQuery(args, api, extraOptions);
  },
  endpoints: (builder) => ({
    getBankingDetails: builder.query<IBankingDetails, string>({
      query: (dealId: string) => `/banking-details?deal_id=${dealId}`,
    }),
  }),
});

export const { useGetBankingDetailsQuery } = BankingDetailsApi;
