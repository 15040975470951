import { dealsApi } from 'services/deals/deals.service';
import { portfolioApi } from 'services/invest/portfolio.service';
import { entitiesApi } from 'services/invest/entities.service';
import { referralApi } from 'services/referral/referral.service';
import { investApi } from 'services/invest/invest.service';
import { BankingDetailsApi } from 'services/banking/banking.service';

export default [
  dealsApi.middleware,
  portfolioApi.middleware,
  entitiesApi.middleware,
  referralApi.middleware,
  investApi.middleware,
  BankingDetailsApi.middleware,
];
