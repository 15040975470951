/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Text, Group, Stack, Title, Button, Alert, Modal, Textarea } from '@mantine/core';
import DetailsContainer from 'components/Common/DetailsContainer/DetailsContainer';
import { formatDate } from 'utils/datetime';
import { formatNumberShort } from 'utils/currencySymbols';
import { PATH_ROOT } from 'shared/constants';
import { useAuth0 } from '@auth0/auth0-react';
import backendConfig from 'config/backend.config';
import { toast } from 'react-toastify';
import { fetchData } from 'utils/apiUtils';
import { ProductAnalytics, PaEvents } from 'analytics';
import InvestmentInput from 'components/Common/InvestmentInput';
import { useDealStatus } from 'hooks/useDealStatus';
import { useDisclosure } from '@mantine/hooks';
import { dealsFields } from './utils';
import BasicSettingsPage from 'pages/settings/basic/BasicSettingsPage';

const InvestmentForm: React.FC<{
  data?: any;
  currency: string;
  isUserVerified: boolean;
  user: any;
  onInvestClick: (value: number) => void;
}> = ({ data, currency, isUserVerified, user, onInvestClick }) => {
  const [showAccountModal, accountModalHandler] = useDisclosure(false);
  const [showPassModal, passModalHandler] = useDisclosure(false);
  const { dealId } = useParams<{ dealId: string }>();
  const [investmentValue, setInvestmentValue] = useState<number | undefined>(undefined);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const { start_date: startDate, end_date: endDate } = data || { start_date: '', end_date: '' };

  const { isDisabled } = useDealStatus({ startDate, endDate });

  const passDeal = async () => {
    try {
      const token = await getAccessTokenSilently();

      ProductAnalytics.trackEvent(PaEvents.PASS_DEAL, {
        deal_id: dealId,
        reason: message,
      });

      await fetchData({
        url: `${backendConfig.baseUrl}v1/subscriptions`,
        method: 'PUT',
        token,
        data: { reason: message, deal_id: dealId, preference: 'OPT_OUT' },
      });
      toast.success('Thanks for your feedback');
      navigate(PATH_ROOT);
    } catch (error) {
      console.error('Failed to pass the deal:', error);
    } finally {
      passModalHandler.close();
      setMessage(''); // Clear the message regardless of success or failure
    }
  };

  const handleInvestButtonClick = () => {
    if (isUserVerified) {
      if ((investmentValue === undefined || investmentValue < data?.min_validation) && user && isUserVerified) {
        const minInvest = formatNumberShort(data?.min_validation);
        setMessage(`Minimum we can accept is ${currency}${minInvest}`);
        ProductAnalytics.trackEvent(PaEvents.INVEST_CLICK, {
          deal_id: dealId,
          amount: investmentValue,
          is_verified: true,
          low_value: true,
        });
      } else {
        setMessage(undefined);
        onInvestClick(investmentValue || 0);
      }
    } else {
      accountModalHandler.open();
      ProductAnalytics.trackEvent(PaEvents.INVEST_CLICK, {
        deal_id: dealId,
        amount: investmentValue,
        is_verified: false,
        low_value: false,
      });
    }
  };

  return (
    <>
      <Card shadow="lg">
        <Stack spacing="sm" mt="sm">
          <Title order={4} weight={500}>
            Invest now
          </Title>
          <Text c="dimmed" size="sm">
            Closing by {formatDate(data?.end_date, 'date')}
          </Text>
          <InvestmentInput
            currency={currency}
            minInvestment={data?.min_investment}
            investmentValue={investmentValue}
            setInvestmentValue={(value) => setInvestmentValue(value as number)}
            isDisabled={isDisabled}
          />
          {message && !showPassModal && <Alert>{message}</Alert>}
        </Stack>
        <Group grow mt="sm">
          <Button onClick={handleInvestButtonClick} disabled={isDisabled}>
            {isUserVerified ? 'INVEST' : 'ADD ACCOUNT INFO TO INVEST'}
          </Button>
          {isUserVerified && (
            <Button
              variant="outline"
              disabled={isDisabled || !isUserVerified || !user}
              onClick={() => {
                setMessage('');
                passModalHandler.open();
              }}
            >
              PASS
            </Button>
          )}
        </Group>
        <DetailsContainer fields={dealsFields} data={data} currencyCode={data?.currency} />
      </Card>
      <Modal opened={showPassModal} onClose={passModalHandler.close} title="Why would you like to pass the deal?">
        <Stack>
          <Textarea
            label="Help us understand your investment preferences by sharing your reasons for passing"
            value={message}
            minRows={5}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(event.currentTarget.value)}
          />
          <Button fullWidth onClick={passDeal}>
            Submit
          </Button>
        </Stack>
      </Modal>
      <Modal opened={showAccountModal} onClose={accountModalHandler.close} title="Confirm Basic Info">
        <BasicSettingsPage onSubmitSuccess={accountModalHandler.close} />
      </Modal>
    </>
  );
};

export default InvestmentForm;
