import React, { useEffect, memo } from 'react';
import HelloSign from 'hellosign-embedded';
import { PaEvents, ProductAnalytics } from 'analytics';

interface HelloDocProps {
  signUrl: string;
  dealId: string;
  totalAmount: number;
  onSuccess: (data: any) => void;
  onError: (error: { signatureId: string; code: 'any' }) => void;
  onCancel: () => void;
}

const HelloDoc: React.FC<HelloDocProps> = ({ signUrl, dealId, totalAmount, onSuccess, onError, onCancel }) => {
  useEffect(() => {
    if (!signUrl) return undefined;

    const client = new HelloSign();
    client.open(signUrl, {
      clientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID || '', 
      skipDomainVerification: process.env.REACT_APP_ENV === 'dev' || false,
      allowCancel: true,
      // debug: true,
    });

    const handleSign = (data: any) => {
      ProductAnalytics.trackEvent(PaEvents.ESIGN, {
        esign_status: 'success',
        deal_id: dealId,
        total_amount: totalAmount,
      });
      onSuccess(data);
    };

    const handleCancel = () => {
      ProductAnalytics.trackEvent(PaEvents.ESIGN, {
        esign_status: 'cancelled',
        deal_id: dealId,
        total_amount: totalAmount,
      });
      onCancel();
    };

    const handleError = (error: any) => {
      ProductAnalytics.trackEvent(PaEvents.ESIGN, {
        esign_status: 'error',
        deal_id: dealId,
        total_amount: totalAmount,
      });
      onError(error);
    };

    client.on('sign', handleSign);
    client.on('cancel', handleCancel);
    client.on('error', handleError);
    return () => {
      client.off('sign', () => {});
      client.off('cancel', () => {});
    };
  }, []);

  return null; 
};

export default memo(HelloDoc);
