import React from 'react';
import Persona from 'persona';
import PersonaFrame from 'pages/settings/identity/PersonaWrapper';

interface PersonaInquiryProps {
  inquiryId: string;
  sessionToken: string;
  onComplete: () => void;
}

const PersonaInquiry: React.FC<PersonaInquiryProps> = ({ inquiryId, sessionToken, onComplete }) => (
  <PersonaFrame>
    <Persona.Inquiry
      inquiryId={inquiryId}
      sessionToken={sessionToken}
      environmentId={process.env.REACT_APP_PERSONA_ENVIRONMENT_ID}
      onComplete={onComplete}
      onCancel={onComplete}
    />
  </PersonaFrame>
);

export default PersonaInquiry;
