/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react';
import { SimpleGrid } from '@mantine/core';
import { useSelector } from 'react-redux';
import { hideNotification, showNotification } from '@mantine/notifications';
import { dealListLoadErrorNotification } from 'pages/homepage/HomepageNotifications';
import { useGetDealsQuery } from 'services/deals/deals.service';
import { ProductAnalytics, PaEvents } from 'analytics';
import DealsSkeleton from './DealsSkeleton';
import DealCard from './DealCard';

type Props = any;

const Deals: React.FC<Props> = (props) => {
  const token = useSelector((state: any) => state.auth0.token);
  const skip = !token;
  const { data: deals, error, isLoading } = useGetDealsQuery(undefined, { skip });

  useEffect(() => {
    if (error) {
      showNotification(dealListLoadErrorNotification);
    }
    return () => {
      hideNotification(dealListLoadErrorNotification.id as string);
    };
  }, [error]);

  if (error || isLoading || !deals) {
    return <DealsSkeleton />;
  }

  const handleCardClick = (deal_id: string, deal_name: string, external?: boolean, external_platform?: string) => {
    ProductAnalytics.trackEvent(PaEvents.DEAL_CARD_CLICK, {
      deal_id,
      deal_name,
      external_platform,
      deal_type: external ? 'external' : 'internal',
    });
  };

  return (
    <SimpleGrid
      cols={6}
      spacing="xl"
      breakpoints={[
        { maxWidth: 1920, cols: 5, spacing: 'xl' },
        { maxWidth: 1600, cols: 4, spacing: 'xl' },
        { maxWidth: 1200, cols: 3, spacing: 'lg' },
        { maxWidth: 992, cols: 2, spacing: 'md' },
        { maxWidth: 768, cols: 1, spacing: 'sm' },
      ]}
      {...props}
    >
      {deals?.items.map((i) => (
        <DealCard
          key={i.id}
          id={i.id}
          bannerUrl={i.banner_url}
          location={i.location}
          logoUrl={i.logo_url}
          name={i.company_name}
          summary={i.company_summary}
          tags={i.tags}
          endDate={i.end_date}
          startDate={i.start_date}
          external={i.external}
          externalLink={i.external_link ?? ''}
          externalPlatform={i.external_platform ?? ''}
        />
      ))}
    </SimpleGrid>
  );
};

export default Deals;
