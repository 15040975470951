import { NotificationProps } from '@mantine/notifications';

// eslint-disable-next-line import/prefer-default-export
export const dealListLoadErrorNotification: NotificationProps = {
  id: 'homepage-page__deal-list-load-error',
  color: 'red',
  title: 'Something went wrong',
  message:
    'We could not display the investment opportunities. Please refresh the page. If the error persists, contact support.',
  disallowClose: true,
  autoClose: false,
};
