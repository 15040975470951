export enum PaEvents {
  PAGE_VIEW = 'Page View',
  USER_LOGGED_IN = 'User Logged In',
  REFERRAL = 'Referral button click',
  DOCUMENT_VIEW = 'Document view',
  DEAL_CARD_CLICK = 'Deal card click',
  PASS_DEAL = 'Pass Deal',
  DEAL_INFO = 'Deal Info click',
  COMMIT_DEAL = 'Commit',
  ESIGN = 'Esign commit',
  USER_DOCUMENTS = 'User Documents',
  INVEST_CLICK = 'Invest Clicked',
}
