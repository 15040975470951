import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Text, Title } from '@mantine/core';

import withPublicLayout from 'hocs/withPublicLayout';
import Deals from './components/Deals/Deals';

const Homepage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    const returnPath = sessionStorage.getItem('returnPath');
    if (returnPath) {
      sessionStorage.removeItem('returnPath');
      navigate(returnPath);
    }
  }, [navigate]);

  useEffect(() => {
    if (location.pathname !== '/') {
      navigate(location.pathname.substring(1));
    }
  }, [location.pathname, navigate]);

  return (
    <div className="homepage">
      <Title order={1} size="xx-large" weight={800}>
        Investment opportunities
      </Title>
      <Text color="dimmed">All companies are vetted and passed due diligence.</Text>
      <Deals my="lg" />
    </div>
  );
};

export default withPublicLayout(Homepage);
