import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery } from 'utils/apiUtils';
import { RootState } from 'store/store';
import { IDeals } from 'features/Deal';
import moment from 'moment';
import { IDealOverviews } from 'features/Deal/models/deals.model';

const getDealsQueryFn = () => {
  const now = moment();
  const startDate = now.clone().subtract(6, 'months').startOf('day').toISOString();
  const endDate = now.clone().add(30, 'days').endOf('day').toISOString();
  const encodedStartDate = encodeURIComponent(startDate);
  const encodedEndDate = encodeURIComponent(endDate);

  return `/deals?start_date=${encodedStartDate}&end_date=${encodedEndDate}`;
};

export const dealsApi = createApi({
  reducerPath: 'dealsApi',
  baseQuery: async (args, api, extraOptions) => {
    const { token } = (api.getState() as RootState).auth0;
    const baseQuery = createBaseQuery(token);
    return baseQuery(args, api, extraOptions);
  },
  endpoints: (builder) => ({
    getDealById: builder.query<IDeals, string>({
      query: (dealId: string) => `/deals/${dealId}`,
    }),
    getDeals: builder.query<IDealOverviews, void>({
      query: getDealsQueryFn,
    }),
  }),
});

export const { useGetDealByIdQuery, useGetDealsQuery } = dealsApi;
