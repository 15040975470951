import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from '@mantine/core';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Footer from 'layout/components/Footer/Footer';
import TopNavigation from 'layout/components/TopNavigation/TopNavigation';

const SettingsPageLayout: React.FC = () => (
  <>
    <TopNavigation />
    <Container size="xs">
      <Outlet />
    </Container>
    <Footer />
  </>
);

export default withAuthenticationRequired(SettingsPageLayout);
