export const PATH_ROOT = '/';

export const PATH_LOGIN = '/login';
export const PATH_LOGOUT = '/logout';

export const PATH_DEAL_PAGE = '/:dealId';
export const PATH_DEAL_INVESTMENT = '/:dealId/invest';

export const PATH_SETTINGS = '/settings';
export const PATH_INVITE_FRIENDS = '/invite';

export const PATH_SETTINGS_BASIC = `${PATH_SETTINGS}/basic`;
export const PATH_SETTINGS_ENTITY = `${PATH_SETTINGS}/entity`;

export const PATH_DASHBOARD = '/dashboard';

export const PATH_SIGNUP = '/register';
export const PATH_WALLET = '/wallet';
export const PATH_ACTIVE_DEALS = '/deals';
export const PATH_SUPPORT = '/support';
