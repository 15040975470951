import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { MantineProvider, MantineTheme, LoadingOverlay } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { useAuth0 } from '@auth0/auth0-react';
import { initializeApp } from 'firebase/app';
import firebaseConfig from 'config/firebase.config';
import NotFoundPage from 'pages/error/404/NotFoundPage';
import Homepage from 'pages/homepage/Homepage';
import Login from 'pages/login/Login';
import Logout from 'pages/logout/Logout';
import SettingsPageLayout from 'pages/settings/SettingsPageLayout';
import BasicSettingsPage from 'pages/settings/basic/BasicSettingsPage';
import theme from './styles/theme';
import {
  PATH_LOGIN,
  PATH_LOGOUT,
  PATH_ROOT,
  PATH_SETTINGS,
  PATH_DASHBOARD,
  PATH_DEAL_PAGE,
  PATH_DEAL_INVESTMENT,
  PATH_INVITE_FRIENDS,
} from 'shared/constants';
import './index.scss';
import { setShouldConfirm } from 'pages/settings/SettingsSlice';
import { useAppDispatch } from 'store/hooks';
import DealPage from 'pages/DealPage';
import DashboardPage from 'pages/DashboardPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InvestmentPage from 'pages/InvestmentPage';
import InvitePage from 'pages/InvitePage';
import { ProductAnalytics } from 'analytics';
import AuthCallback from 'pages/AuthCallback';

ProductAnalytics.init(process.env.REACT_APP_PRODUCT_ANALYTICS || '', {
  debug: process.env.REACT_APP_ENV !== 'prd',
  sessionRecording: process.env.REACT_APP_ENV === 'prd',
});

const App = () => {
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently, user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const location = useLocation();
  initializeApp(firebaseConfig);

  useEffect(() => {
    if (location.pathname !== '/') {
      sessionStorage.setItem('returnPath', location.pathname);
    }
  }, [user]);

  useEffect(() => {
    const fetchShouldConfirmUser = async () => {
      const accessToken = await getAccessTokenSilently();
      const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL || '';
      const userId = user?.sub || '';

      if (!userId) return;

      try {
        const res = await fetch(`${baseUrl}v1/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Access-Control-Allow-Headers': '*',
          },
        });
        const data = await res.json();

        ProductAnalytics.identifyUser(data?.email || '', {
          id: userId,
          email: data?.email || '',
          name: `${data?.first_name} ${data?.last_name}` || '',
        });

        if (data?.accredited_investor === true) {
          dispatch(setShouldConfirm(false));
        } else {
          dispatch(setShouldConfirm(true));
        }
      } catch (error) {
        dispatch(setShouldConfirm(true));
      }
    };

    if (user) {
      fetchShouldConfirmUser();
    }
  }, [user, getAccessTokenSilently, dispatch]);

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  if (!isAuthenticated) {
    if (location.pathname !== '/') {
      sessionStorage.setItem('returnPath', location.pathname);
    }
    loginWithRedirect({ appState: { returnTo: location.pathname } });
    return null;
  }

  return (
    <MantineProvider theme={theme as unknown as MantineTheme} withCSSVariables withGlobalStyles withNormalizeCSS>
      <NotificationsProvider>
        <ToastContainer />
        <Routes>
          <Route path={PATH_ROOT} index element={<Homepage />} />
          <Route path={PATH_LOGIN} element={<Login />} />
          <Route path={PATH_LOGOUT} element={<Logout />} />
          <Route path={PATH_DEAL_INVESTMENT} element={<InvestmentPage />} />
          <Route path={PATH_DEAL_PAGE} element={<DealPage />} />
          <Route path={PATH_DASHBOARD} element={<DashboardPage />} />
          <Route path={PATH_SETTINGS} element={<SettingsPageLayout />}>
            <Route index element={<Navigate replace to="basic" />} />
            <Route path="basic" element={<BasicSettingsPage />} />
          </Route>
          <Route path={PATH_INVITE_FRIENDS} element={<InvitePage />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/callback" element={<AuthCallback />} />
      </Routes>
      </NotificationsProvider>
    </MantineProvider>
  );
};

export default App;
