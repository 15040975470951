import React, { useEffect, useRef } from 'react';

const PersonaFrame = ({ children }: { children: any }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const iframe = containerRef.current?.querySelector('iframe');
    if (iframe) {
      iframe.style.width = '100%';
      iframe.style.height = '500px';
    }
  }, []);

  return (
    <div ref={containerRef} style={{ width: '100%' }}>
      {children}
    </div>
  );
};

export default PersonaFrame;
