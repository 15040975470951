/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react';
import CryptoJS from 'crypto-js';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';

const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();

  const domain = process.env.REACT_APP_DOMAIN || '';

  // Function to encode a string in Base64 URL format
  const base64URLEncode = (str: CryptoJS.lib.WordArray) => {
    const base64 = CryptoJS.enc.Base64.stringify(str);
    return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/g, '');
  };

  // Function to compute the SHA-256 hash of a given input
  const sha256 = async (buffer: CryptoJS.lib.WordArray) => {
    const hash = CryptoJS.SHA256(buffer);
    return base64URLEncode(hash);
  };

  // Generate and store the code verifier
  const generateCodeVerifier = async () => {
    const verifier = CryptoJS.lib.WordArray.random(32);
    const challenge = await sha256(verifier);

    // Store code_verifier in session storage
    sessionStorage.setItem('code_verifier', verifier.toString(CryptoJS.enc.Base64));

    return challenge;
  };

  const initiateLogin = useCallback(async () => {
    const codeChallenge = await generateCodeVerifier();
    const returnTo = location.pathname + location.search;
    await loginWithRedirect({
      authorizationParams: {
        code_challenge_method: 'S256',
        code_challenge: codeChallenge,
        redirect_uri: `${domain}/callback`,
        state: returnTo,
      },
    });
  }, [loginWithRedirect, domain, location]);

  useEffect(() => {
    initiateLogin();
  }, [initiateLogin]);

  return (
    <div>
      <p>Redirecting to login...</p>
    </div>
  );
};

export default Login;
