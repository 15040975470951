import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal, Select, Button, Group } from '@mantine/core';

import { Entity } from 'models/invest/entity.api.model';
import LoadingOverlay from 'components/Common/LoadingOverlay';
import useToggle from 'hooks/useToggle';
import backendConfig from 'config/backend.config';
import { fetchData } from 'utils/apiUtils';
import PersonaInquiry from 'features/Kyc/PersonaInquiry';
import { MessageModal } from 'features/Invest/components/DynamicMessageModal';

import EntityStepper from './EntityStepper';
import UboTable from './UboTable';

interface EntityModalProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  mode: 'new' | 'edit'; // Define the mode
  initialData?: any; // Data for editing, if available
  disabledIndividual: boolean;
}

let apiResponseEntity: Entity | null = null;
const EntityModal: React.FC<EntityModalProps> = ({ opened, setOpened, mode, initialData = {}, disabledIndividual }) => {
  const [entityType, setEntityType] = useState<string | null>(initialData?.type || null);

  const token = useSelector((state: any) => state.auth0.token);
  const [modalType, setModalType] = useState<'static-message' | 'ubo-message' | null>(null);
  const [isPersonaOpen, togglePersonaOpen, setIsPersonaOpen] = useToggle(false);
  const [verificationTokens, setVerificationTokens] = useState<[any] | []>([]);
  const [currentVerificationToken, setCurrentVerificationToken] = useState<{
    inquiry_id: string;
    session_token: string;
  } | null>(null);
  const [isLoadingStatus, toggleStatus, setLoading] = useToggle(false);

  useEffect(() => {
    if (mode === 'edit') {
      setEntityType(initialData.entityType); // Pre-fill entity type if editing
    }
  }, [mode, initialData]);

  const handleEntitySelection = (value: string) => {
    setEntityType(value);
  };

  const onModalClose = () => {
    setModalType(null);
    setOpened(false);
    setIsPersonaOpen(false);
    setVerificationTokens([]);
  };

  const fetchVerificationTokens = async (entityData: Entity) => {
    // Mock API call to fetch verification token
    try {
      const data = await fetchData({
        url: `${backendConfig.baseUrl}v1/inquiries?entity_id=${entityData.id}`,
        method: 'GET',
        token,
      });
      return data;
    } catch (err) {
      console.error('Error fetching token:', err);
      return [];
    }
  };

  const onKycVerificationStart = async (entity: Entity | null) => {
    apiResponseEntity = entity;
    if (entity?.status === 'NOT_VERIFIED') {
      if (entity.country === 'USA' || entity.type === 'INDIVIDUAL') {
        try {
          setLoading(true);
          const personaTokens = await fetchVerificationTokens(entity);
          setLoading(false); // Hide loading overlay
          if (personaTokens.length > 0) {
            setVerificationTokens(personaTokens);
            const [nextToken, ...remainingTokens] = personaTokens;
            setCurrentVerificationToken({ ...nextToken });
            setVerificationTokens(remainingTokens);
            setIsPersonaOpen(true);
          } else if (entity.type !== 'INDIVIDUAL') {
            setModalType('ubo-message');
          }
        } catch (err) {
          setLoading(false);
          toast.error(`Something went wrong. Please try after sometime`);
        }
      } else {
        setModalType('static-message');
      }
    }
  };

  const handlePersonaCloseModel = () => {
    setIsPersonaOpen(false);
    setVerificationTokens([]);
  };

  const handlePersonaSuccess = () => {
    if (verificationTokens.length > 0) {
      const [nextToken, ...remainingTokens] = verificationTokens;
      setCurrentVerificationToken({ ...nextToken });
      setVerificationTokens([...remainingTokens]);
    } else {
      handlePersonaCloseModel();
      if (apiResponseEntity?.type !== 'INDIVIDUAL') {
        setModalType('ubo-message');
      } else {
        onModalClose();
        toast.success('KYC is completed, please refresh the page to continue investing.');
      }
    }
  };

  const renderModalContent = () => {
    if (isPersonaOpen && currentVerificationToken) {
      return (
        <PersonaInquiry
          key={currentVerificationToken.inquiry_id}
          inquiryId={currentVerificationToken.inquiry_id}
          onComplete={handlePersonaSuccess}
          sessionToken={currentVerificationToken.session_token}
        />
      );
    }

    if (modalType) {
      return <MessageModal isVisible={!!modalType} onClose={onModalClose} type={modalType} data={apiResponseEntity} />;
    }

    return (
      <>
        <div style={{ flex: 1, overflowY: 'auto' }}>
          {mode === 'new' && !entityType ? (
            <Select
              label="Select Entity Type"
              placeholder="Pick one"
              data={[
                { value: 'INDIVIDUAL', label: 'Individual', disabled: disabledIndividual },
                { value: 'COMPANY', label: 'Firm' },
              ]}
              onChange={handleEntitySelection}
            />
          ) : (
            <EntityStepper
              entityType={entityType}
              setOpened={setOpened}
              initialData={initialData}
              onKycVerificationStart={onKycVerificationStart}
            />
          )}
        </div>

        {!entityType && mode === 'new' && (
          <Group position="right" mt="md" style={{ paddingTop: '1rem', borderTop: '1px solid #e0e0e0' }}>
            <Button onClick={() => setOpened(false)}>Cancel</Button>
          </Group>
        )}
      </>
    );
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={onModalClose}
        size={isPersonaOpen || modalType ? '50%' : '70%'}
        title={isPersonaOpen || !modalType ? 'Verify your information' : 'Create new investment Entity'}
        styles={{
          modal: {
            display: 'flex',
            flexDirection: 'column',
            height: isPersonaOpen || modalType ? 'auto' : '80vh',
          },
          body: {
            flexGrow: 1,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        {renderModalContent()}
      </Modal>

      {isLoadingStatus && <LoadingOverlay />}
    </>
  );
};

EntityModal.defaultProps = {
  initialData: {},
};

export default EntityModal;
