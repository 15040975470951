import React, { useEffect } from 'react';
import withPublicLayout from 'hocs/withPublicLayout';
import DashboardPortfolio from 'features/Dashboard/components/DashboardPortfolio';

import { ProductAnalytics } from 'analytics';

const DashboardPage = () => {
  useEffect(() => {
    ProductAnalytics.trackPageView('dashboard');
  }, []);

  return <DashboardPortfolio />;
};

export default withPublicLayout(DashboardPage);
