import React from 'react';
import { Title } from '@mantine/core';
import InvestmentInput from 'components/Common/InvestmentInput';

interface InvestmentFormProps {
  investmentValue: number | undefined;
  setInvestmentValue: (value: number) => void;
  currency: string;
  min_validation: number;
}

const InvestmentForm: React.FC<InvestmentFormProps> = ({
  investmentValue,
  setInvestmentValue,
  currency,
  min_validation,
}) => (
  <div>
    <Title order={2} mb="sm">
      Complete your investment
    </Title>
    <InvestmentInput
      currency={currency}
      minInvestment={min_validation}
      investmentValue={investmentValue || 0}
      setInvestmentValue={setInvestmentValue}
      label="Investment amount"
      showErrorMessage={!!((investmentValue && investmentValue <= min_validation) || !investmentValue)}
    />
  </div>
);

export default InvestmentForm;
