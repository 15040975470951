import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const AuthCallback: React.FC = () => {
  const { handleRedirectCallback } = useAuth0();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const processLogin = async () => {
      try {
        const codeVerifier = sessionStorage.getItem('code_verifier');

        // Log missing code_verifier for debugging
        if (!codeVerifier) {
          console.warn('code_verifier missing. Continuing login process without it.');
        }

        // Handle redirect and token exchange
        await handleRedirectCallback();

        // Clear code_verifier after use
        sessionStorage.removeItem('code_verifier');

        // Redirect after successful login
        const returnPath = sessionStorage.getItem('returnPath');
        if (returnPath) {
          sessionStorage.removeItem('returnPath');
          navigate(returnPath);
        } else {
          navigate('/');
        }
      } catch (err) {
        console.error('Error handling login callback:', err);
        setError('Login failed. Please try again.');
      }
    };

    processLogin();
  }, [handleRedirectCallback, navigate]);

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  return <p>Processing login...</p>;
};

export default AuthCallback;
