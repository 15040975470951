import React from 'react';
import { Avatar, Badge, Card, Group, Image, Text, Title, useMantineTheme, Tooltip, Stack } from '@mantine/core';
import { useDealStatus } from 'hooks/useDealStatus';
import { Link } from 'react-router-dom';

export interface DealCardProps {
  id: string;
  bannerUrl: string;
  location: {
    city: string;
    country: string;
  };
  logoUrl: string;
  name: string;
  summary: string;
  tags: string[];
  endDate: string;
  startDate: string;
  external: boolean;
  externalLink: string;
  externalPlatform: string;
}

const DealCard: React.FC<DealCardProps> = ({
  id,
  bannerUrl,
  location,
  logoUrl,
  name,
  summary,
  tags,
  endDate,
  startDate,
  external,
  externalLink,
  externalPlatform,
}) => {
  const theme = useMantineTheme();

  const { dealStatus, isDisabled } = useDealStatus({ startDate, endDate });

  const statusColorMap = { LIVE: 'green', CLOSED: 'red', ANNOUNCED: 'gray' };

  const isExternal = external ?? false;
  const platformName = externalPlatform ?? '';

  const activeProps = {
    component: Link,
    to: isExternal ? externalLink : id,
    style: { cursor: 'pointer' },
    target: isExternal ? '_blank' : undefined,
    rel: isExternal ? 'noopener noreferrer' : undefined,
  };

  const inactiveProps = { style: { cursor: 'not-allowed' } };

  const tooltipText =
    isExternal && !isDisabled ? `This will redirect to our partner ${platformName} platform` : undefined;

  const CardUI = (
    <Card
      p="lg"
      withBorder
      {...(!isDisabled ? activeProps : inactiveProps)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        ...(isDisabled ? inactiveProps.style : activeProps.style),
      }}
    >
      <Card.Section>
        <Image src={bannerUrl} alt={name} height={150} />
      </Card.Section>

      <Stack spacing="sm" style={{ flexGrow: 1 }}>
        <Group mt={-theme.spacing.md * 1.75} position="apart">
          <Avatar style={{ border: '3px solid white' }} src={logoUrl} alt={name} size={60} radius="md" />
          <Badge radius="lg" color={statusColorMap[dealStatus]} style={{ zIndex: 2 }} variant="filled">
            {dealStatus}
          </Badge>
        </Group>
        <Stack spacing={3}>
          <Title order={4}>{name}</Title>
          <Text lineClamp={4}>{summary}</Text>
          <Text size="sm" c="dimmed">
            {location.city}, {location.country}
          </Text>
        </Stack>

        <Group spacing="xs" style={{ flexGrow: 1, alignItems: 'flex-end' }}>
          {tags.map((tag: string) => (
            <Badge key={tag} radius="sm">
              {tag}
            </Badge>
          ))}
        </Group>
      </Stack>
    </Card>
  );

  return tooltipText ? (
    <Tooltip label={tooltipText} withArrow>
      {CardUI}
    </Tooltip>
  ) : (
    CardUI
  );
};

export default DealCard;
