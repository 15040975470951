import React from 'react';
import { Table, Loader, Text } from '@mantine/core';
import { useGetBankingDetailsQuery } from 'services/banking/banking.service';

interface BankDetailsDisplayProps {
  dealId: string;
}

const BankDetailsDisplay: React.FC<BankDetailsDisplayProps> = ({ dealId }) => {
  const { data: bankDetails, isLoading, isError } = useGetBankingDetailsQuery(dealId);

  if (isError) {
    return <Text color="red">Error loading bank details. Please try again later.</Text>;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (!bankDetails) {
    return <Text>No bank details available.</Text>;
  }

  const bankDetailsRows = [
    { key: 'Beneficiary Name', value: bankDetails.beneficiary_name },
    { key: 'Account Number', value: bankDetails.account_number },
    { key: 'Type of Account', value: bankDetails.account_type },
    { key: 'Beneficiary Address', value: bankDetails.beneficiary_address },
    { key: 'Bank Name', value: bankDetails.bank_name },
    { key: 'SWIFT Code / BIC', value: bankDetails.swift_bic_code },
    { key: 'ACH/ABA Routing Number', value: bankDetails.aba_routing_number },
    { key: 'Bank Address', value: bankDetails.bank_address },
  ];

  return (
    <Table striped withBorder>
      <tbody>
        {bankDetailsRows.map((detail) => (
          <tr key={detail.key}>
            <td>{detail.key}</td>
            <td align="right">{detail.value}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default BankDetailsDisplay;
