import React, { useRef, useEffect } from 'react';
import { columnDefs } from 'features/Dashboard/utils/columnDefs';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './Agrid.scss';

const AGGridTable: React.FC<{ rowData: any[] }> = ({ rowData }) => {
  const gridRef = useRef<any>(null);

  // Auto size columns to fit content
  const autoSizeAll = () => {
    const allColumnIds: string[] = [];
    gridRef.current.columnApi?.getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi?.autoSizeColumns(allColumnIds);
  };

  const onGridReady = (params: any) => {
    gridRef.current = params.api;

    // Size columns to fit grid width
    gridRef.current.sizeColumnsToFit();

    // Auto size columns based on content
    autoSizeAll();
  };

  useEffect(() => {
    const handleResize = () => {
      if (gridRef.current) {
        gridRef.current.sizeColumnsToFit();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className="ag-theme-alpine"
      style={{
        maxHeight: 400, // Maximum height for the table
        width: '100%',
        overflow: 'auto', // Allows scrolling if content exceeds max height
      }}
    >
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        domLayout="autoHeight"
        defaultColDef={{
          resizable: true, // Allow columns to be resizable
        }}
        getRowId={(params) => params.data.key} // Ensure unique row IDs
      />
    </div>
  );
};

export default AGGridTable;
