import React from 'react';
import { Input, Title } from '@mantine/core';
import { formatNumber } from 'utils/currencySymbols';

const InvestmentInput: React.FC<{
  currency: string;
  minInvestment: number;
  investmentValue: number | undefined;
  setInvestmentValue: (value: number) => void;
  isDisabled?: boolean;
  label?: string;
  showErrorMessage?: boolean;
}> = ({ currency, minInvestment, investmentValue, setInvestmentValue, isDisabled, label, showErrorMessage }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^0-9]/g, '');
    setInvestmentValue(Number(value));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
      event.preventDefault();
    }
  };

  return (
    <Input.Wrapper
      label={label && <Title order={4}>{label}</Title>}
      error={
        showErrorMessage && (!investmentValue || investmentValue < minInvestment)
          ? `Minimum investment amount is ${currency}${formatNumber(minInvestment)}`
          : ''
      }
    >
      <Input
        sx={{ minWidth: '300px' }}
        icon={<div>{currency}</div>}
        radius="sm"
        value={investmentValue && formatNumber(investmentValue)}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={isDisabled}
      />
    </Input.Wrapper>
  );
};

InvestmentInput.defaultProps = {
  isDisabled: false,
  label: '',
  showErrorMessage: false,
};
export default InvestmentInput;
