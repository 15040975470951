import React from 'react';
import { Group, Divider, Anchor, Text, Stack } from '@mantine/core';
import { FieldTypesEnum, formatDate } from 'utils/datetime';
import { getCurrencySymbol, formatNumberWithCommas } from 'utils/currencySymbols';
import { TRANSLATE_STRING } from 'i18n/messages';
import { trackEventsOnClick } from 'analytics';
import { formatString } from 'features/Deal/components/utils';

type Field = {
  key: string;
  label: string;
  type: FieldTypesEnum;
};

type Props = {
  fields: Field[];
  data: Record<string, any>;
  currencyCode?: string;
};

const Links: Record<string, string> = {
  Direct:
    'https://www.tokenfolio.co/faqs?questionId=08d65855-709a-41e9-9806-7d1e1c3fa6e4&appDefId=14c92d28-031e-7910-c9a8-a670011e062d',
  Indirect:
    'https://www.tokenfolio.co/faqs?questionId=08d65855-709a-41e9-9806-7d1e1c3fa6e4&appDefId=14c92d28-031e-7910-c9a8-a670011e062d',
};

const DetailsContainer: React.FC<Props> = ({ fields, data, currencyCode = 'USD' }) => {
  const currency = getCurrencySymbol(currencyCode);

  const formatValue = (field: Field, value: any): React.ReactNode => {
    if (value === undefined || value === null) return null;

    switch (field.type) {
      case FieldTypesEnum.Currency:
        return `${currency}${formatNumberWithCommas(value)}`;
      case FieldTypesEnum.Percentage:
        return `${value}%`;
      case FieldTypesEnum.DateTime:
        return formatDate(value, 'date');
      case FieldTypesEnum.Link: {
        const link = Links[value] || Links.Indirect;
        return (
          <Anchor
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={trackEventsOnClick}
            data-event="deal_structure_link"
          >
            {formatString(value)}
          </Anchor>
        );
      }
      case FieldTypesEnum.Translate:
        return TRANSLATE_STRING[value as keyof typeof TRANSLATE_STRING];
      default:
        return value;
    }
  };

  return (
    <Stack spacing="sm" mt="md">
      {fields.map((field, index) => {
        const value = field.key.split('.').reduce((acc, part) => acc && acc[part], data);
        if (value == null) {
          return (<></>);
        }
        return (
          <>
            <Group key={field.key} position="apart">
              <Text size="sm">{field.label}</Text>
              <Text size="sm">{formatValue(field, value)}</Text>
            </Group>
            {index !== fields.length - 1 && <Divider />}
          </>
        );
      })}
    </Stack>
  );
};

DetailsContainer.defaultProps = {
  currencyCode: 'USD',
};

export default DetailsContainer;
