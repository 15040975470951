import mixpanelLib from 'mixpanel-browser';
import { PaEvents } from './PaEvents';

interface UserDetails {
  id: string;
  name: string;
  email: string;
}

interface EventProperties {
  [key: string]: any;
}

class ProductAnalyticsService {
  private productAnalyticsInitialized = false;
  /**
   * Initialize Mixpanel with project token and session recording flag.
   * @param token - Mixpanel project token.
   * @param config - Optional configuration for Mixpanel.
   */

  init(
    token: string,
    config: { debug?: boolean; sessionRecording?: boolean } = { debug: false, sessionRecording: true }
  ): void {
    mixpanelLib.init(token, {
      debug: config.debug,
      persistence: 'localStorage',
      // track_pageview: "url-with-path", // Track when the path changes, ignoring any query string or hash changes
      record_sessions_percent: config.sessionRecording ? 1 : 0,
    });
    this.productAnalyticsInitialized = true;
  }

  /**
   * Check if Mixpanel is initialized.
   */
  isInitialized(): boolean {
    return this.productAnalyticsInitialized;
  }

  /**
   * Track an event with additional properties and return tracking status.
   * @param eventName - Name of the event from predefined EventNames enum.
   * @param properties - Additional properties to track.
   * @returns Boolean indicating whether the event was successfully tracked.
   */
  trackEvent(eventName: PaEvents, properties: EventProperties = {}): boolean {
    if (this.isInitialized()) {
      try {
        mixpanelLib.track(eventName, properties);
        return true;
      } catch (error) {
        console.error(`Failed to track event "${eventName}":`, error);
        return false;
      }
    }
    return false;
  }

  /**
   * Identify a user by a unique identifier and set their profile properties.
   * @param userId - Unique user identifier.
   * @param userProperties - User properties to set.
   */
  identifyUser(userId: string, userProperties: Partial<UserDetails> = {}): void {
    if (this.isInitialized()) {
      mixpanelLib.identify(userId);
      mixpanelLib.people.set({
        $name: userProperties.name,
        $email: userProperties.email,
        $id: userProperties.id,
      });
    }
  }

  /**
   * Set persistent user details as Super Properties.
   * These properties will be automatically included in all subsequent events.
   * @param userDetails - User details to set as super properties.
   */
  setUserDetails(userDetails: Partial<UserDetails>): void {
    if (this.isInitialized()) {
      mixpanelLib.register(userDetails);
    }
  }

  /**
   * Track a page view event.
   * @param pageName - Name of the page.
   * @returns Boolean indicating whether the page view event was successfully tracked.
   */
  trackPageView(pageName: string, properties: EventProperties = {}): boolean {
    return this.trackEvent(PaEvents.PAGE_VIEW, { ...properties, page: pageName });
  }

  /**
   * Reset Mixpanel (clear user and properties).
   */
  reset(): void {
    if (this.isInitialized()) {
      mixpanelLib.reset();
    }
  }
}

const productAnalyticsService = new ProductAnalyticsService();
export default productAnalyticsService;
