import React from 'react';
import { Modal } from '@mantine/core';
import PersonaInquiry from './PersonaInquiry';
import PersonaFrame from 'pages/settings/identity/PersonaWrapper';

interface PersonaComponentProps {
  inquiryId: string;
  sessionToken: string;
  onComplete: () => void;
  onCloseModel: () => void;
  opened: boolean;
}

const PersonaComponent: React.FC<PersonaComponentProps> = ({
  inquiryId,
  sessionToken,
  onComplete,
  onCloseModel,
  opened,
}) => (
  <Modal opened={opened} onClose={onCloseModel} title="Verify your Information" size="xl">
    <PersonaFrame>
      <PersonaInquiry inquiryId={inquiryId} sessionToken={sessionToken} onComplete={onComplete} />
    </PersonaFrame>
  </Modal>
);

export default PersonaComponent;
